<template>
  <div id="winner-page" class="winner-page">
    <Header />
    <div class="winner-page__content">      
      <CodeFillingDeliveryForm/>
    </div>
    <Footer />  
  </div>
</template>

<script>
import CodeFillingDeliveryForm from '@/components/CodeFillingDelivery-form.vue'
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"

export default {
  components: {
    CodeFillingDeliveryForm   ,
    Header,
     Footer
  },
  created(){
        if (document.querySelectorAll('meta[content="noindex, nofollow"]').length === 0) {
            var m = document.createElement('meta'); 
            m.name = 'robots'; 
            m.content = 'noindex, nofollow'; 
            document.head.appendChild(m);
        }
    },
}
</script>
